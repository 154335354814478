import React from "react"

import Layout from "../../components/layout/layout-stp"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import Panel from "../../components/panels/panel"


const STPReviews = () => (
  <Layout>
    
    <Seo title="Testimonials" bodyClass='stp'/>
    
    
    <div className="column column--30 alignright">
      <div className="sticky">
        <h1 className={style.titleLowercase}>Testimonials</h1>
        <p> Yes, we’re Specyal! See what are Clients, Fans & Affiliates have to say about our vibe.</p>
      </div>
    </div>
    <div className="column column--70">

    <Panel 
        header={`OMG, I am so truly touched by this song ...`}
        content={`I was in tears while I was listening and so full of appreciation, especially hearing Jazzy's voice at the end. Thank you, Thank you! What a thoughtful gift. I will cherish it always!`}
        footer={`PDSB Teacher - Recipient of “Keep Smiling” [Custom Teacher Appreciation Song], Mississauga `}      
        />



    <Panel 
        header={`SPECYAL T PRODUCTIONS is a very professional company to work with. `}
        content={`The process was really simple. I just had to give them the date I needed it for, who it was about and some things about the event/person. The custom song was delivered not only on time, but early for the arrival of my nephew. I really loved the song.
        Now that he's 3 he really likes the song too (He even dnces to it sometimes). The pricing was great and they kept in-touch through the whole process. As a grouping of artists, SPECYAL T PRODUCTIONS really exemplifies the indie market in this country for unsurpassed quality and skill. I wish them the best in the future, and I'll be needing a few more songs since I have another new nephew and some other events that need to have their stamp on it!`}
        footer={`Leslie Thoms - Recipient of "Baby On The Way" [Custom Parent-To-Be Song] & "Ms. Gossip" [Custom B-day Song], 
        Penetanguishene, ON`}      
        />

      <Panel 
        header={`Specyal T has a creative, open and musical mind.`}
        content={`I definitely see something in her that is really hard to come by in today's industry. I really get a sense of how creatively deep she can be. Specyal T and Catherine Marie have the drive, ambition, and talent to actually make their vision successful. The current and future music industry needs more artists like them; visionaries with a purpose!`}
        footer={`Simon Bader - Senior Engineer and Sound Designer, Massive Sound Studios 
        Vaughan, ON`}      
        />

      <Panel 
        header={`Specyal T was able to work her magic!`}
        content={`She produced music that touched a diverse group of cultures and generations at our wedding. She was very creative in her approach and generous with her talent. We would reccomend her for any occasion.`}
        footer={`Dr Peter & Cheryl Kizoff - Recipients of "What I Know" [Custom Wedding Song]
        Tiny, ON`}      
        />
      <Panel 
        header={`We had the privilege of working with Specyal T on her 'Dose of Distinction' album.!`}
        content={`There was an immediate click in personality and this showed in the quality of work we were able to complete in such a short period of time. We thoroughly enjoyed her knowledge and dedication to the art of music; She truly is a professional at what she does. We look forward to continued work in the future.`}
        footer={`Arun Moorthy - Composer/Producer, Slept Productions
        Mississauga, ON`}      
        />

      <Panel 
        header={`SPECYAL T PRODUCTIONS has a unique new sound and vibe.`}
        content={`I think the artists are great and original. The time they put into the personalized songs really make them special."`}
        footer={`Prescilla Josephine - Recipient of "Six Eleven" [Custom B-day Song]
        Toronto, ON`}      
        />
      <Panel 
        header={`Trish is very professional in all aspects of this business.`}
        content={`When on the stage, and in the industry she is known as Specyal T; and her talent is profound! She is amazing and a delight to watch and work with. Don't forget to hire her for your future shows, and buy her music it will move you!`}
        footer={`Liz Taylor - Owner / Make-Up Artist, Faces By Pureness 
        Toronto, ON`}      
        />
      <Panel 
        header={`The biggest thing that hit me when I first heard Specyal T was her unique vocal style and catchy appealing lyrics`}
        content={`Which gives her an originality thats hard to find in a lot of artists. When I sent her a beat and she told me what concept she was going to run with, I remember only a few days later she already had a rough cut of it recorded and it sounded great. The combination of her ability to make a song her own, combined with the work ethic she has, made it one of the best collaborative experiences I've ever had and I look forward to making more music with her in the future`}
        footer={`Eamonn Foley - Composer / Producer
        Cork, Ireland`}      
        />
     
      </div>

  </Layout>
)

export default STPReviews
